import React from 'react'
import classNames from 'classnames'

const Calculator = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    // height={height}
    viewBox="0 0 512 512"
    fill={classNames({ '#FFFFFF': isActive }, { '#797979': !isActive })}
  >
    <path d="M375.467,435.2h-51.2c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h51.2c4.719,0,8.533-3.823,8.533-8.533     S380.186,435.2,375.467,435.2z" />
    <path d="M162.133,307.2c4.719,0,8.533-3.823,8.533-8.533V281.6h17.067c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533     h-17.067v-17.067c0-4.71-3.814-8.533-8.533-8.533s-8.533,3.823-8.533,8.533v17.067h-17.067c-4.719,0-8.533,3.823-8.533,8.533     s3.814,8.533,8.533,8.533H153.6v17.067C153.6,303.377,157.414,307.2,162.133,307.2z" />
    <path d="M426.667,349.867c0-4.71-3.814-8.533-8.533-8.533h-153.6V230.4c0-4.71-3.814-8.533-8.533-8.533     c-4.719,0-8.533,3.823-8.533,8.533v110.933h-153.6c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h153.6v110.933     c0,4.71,3.814,8.533,8.533,8.533c4.719,0,8.533-3.823,8.533-8.533V358.4h153.6C422.852,358.4,426.667,354.577,426.667,349.867z" />
    <path d="M452.267,435.2c4.719,0,8.533-3.823,8.533-8.533V51.2c0-28.237-22.963-51.2-51.2-51.2H102.4     C74.163,0,51.2,22.963,51.2,51.2v409.6c0,28.237,22.963,51.2,51.2,51.2h307.2c28.237,0,51.2-22.963,51.2-51.2     c0-4.71-3.814-8.533-8.533-8.533s-8.533,3.823-8.533,8.533c0,18.825-15.309,34.133-34.133,34.133H102.4     c-18.825,0-34.133-15.309-34.133-34.133V51.2c0-18.825,15.309-34.133,34.133-34.133h307.2c18.825,0,34.133,15.309,34.133,34.133     v375.467C443.733,431.377,447.548,435.2,452.267,435.2z" />
    <path d="M375.467,401.067h-51.2c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h51.2c4.719,0,8.533-3.823,8.533-8.533     S380.186,401.067,375.467,401.067z" />
    <path d="M409.6,196.267V59.733c0-4.71-3.814-8.533-8.533-8.533H110.933c-4.719,0-8.533,3.823-8.533,8.533v136.533     c0,4.71,3.814,8.533,8.533,8.533h290.133C405.786,204.8,409.6,200.977,409.6,196.267z M392.533,187.733H119.467V68.267h273.067     V187.733z" />
    <path d="M324.267,264.533c-4.719,0-8.533,3.823-8.533,8.533s3.814,8.533,8.533,8.533h51.2c4.719,0,8.533-3.823,8.533-8.533     s-3.814-8.533-8.533-8.533H324.267z" />
    <path d="M193.766,395.034c-3.337-3.337-8.73-3.337-12.066,0l-19.567,19.567l-19.567-19.567c-3.337-3.337-8.73-3.337-12.066,0     c-3.337,3.336-3.337,8.73,0,12.066l19.567,19.567L130.5,446.234c-3.337,3.336-3.337,8.73,0,12.066     c1.664,1.664,3.849,2.5,6.033,2.5s4.369-0.836,6.033-2.5l19.567-19.567L181.7,458.3c1.664,1.664,3.849,2.5,6.033,2.5     c2.185,0,4.369-0.836,6.033-2.5c3.336-3.336,3.336-8.73,0-12.066l-19.567-19.567l19.567-19.567     C197.103,403.763,197.103,398.37,193.766,395.034z" />
    <path d="M162.133,153.6c4.719,0,8.533-3.823,8.533-8.533v-34.133c0-4.71-3.814-8.533-8.533-8.533s-8.533,3.823-8.533,8.533     v34.133C153.6,149.777,157.414,153.6,162.133,153.6z" />
  </svg>
)

export default Calculator
