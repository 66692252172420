import * as Yup from "yup"

export const healthSurveyValidationSchema = Yup.object().shape({
  barriersToAdherence: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
  customerSatisfaction: Yup.array().when("healthSurveyType", {
    is: healthSurveyType => healthSurveyType === "a",
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required"),
  }),
  medicationHistory: Yup.string().when("healthSurveyType", {
    is: healthSurveyType => healthSurveyType === "i",
    then: Yup.string().required("This field is required"),
  }),
})
