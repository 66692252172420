import { Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { navigate } from "gatsby"

import Section from "../elements/Section"
import ContentFold from "../page-content/content-fold"
import CheckBoxSection from "../forms/checkbox/checkbox-section"

import {
  barriersToAdherenceOptions,
  customerSatisfactionOptions,
  healthSurveyInitialValues,
  medicationHistoryOptions,
} from "./utils/initialValues"
import { healthSurveyValidationSchema } from "./utils/validationSchema"
import { handleSendHealthSurvey } from "./services/handleSendHealthSurvey"
import FormRadio from "../elements/Form/FormRadio"

const HealthSurvey = ({ location }) => {
  const urlParams = new URLSearchParams(location.search)
  const healthSurveyType = urlParams.get("t") || ""
  const [loading, setLoading] = useState(false)

  const handleCallback = () => {
    setLoading(false)
    navigate("/health-survey/complete")
  }

  const handleErrorCallback = () => {
    setLoading(false)
  }

  const handleSubmit = async values => {
    setLoading(true)
    await handleSendHealthSurvey({
      values,
      callback: handleCallback,
      errorCallback: handleErrorCallback,
      healthSurveyType,
    })
  }

  useEffect(() => {
    if (!healthSurveyType) navigate("/")
  }, [])

  return (
    <ContentFold title="Health Survey" isCentered>
      <Formik
        initialValues={{ ...healthSurveyInitialValues, healthSurveyType }}
        validationSchema={healthSurveyValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Section title="Barriers to Adherence">
              <CheckBoxSection
                title="When you fail to take Entresto at the right time, what are the usual reasons?"
                isRequired
                options={barriersToAdherenceOptions}
                values={values.barriersToAdherence}
                fieldName="barriersToAdherence"
                setFieldValue={setFieldValue}
              />
            </Section>
            {healthSurveyType === "i" ? (
              <Section title="Medication History">
                <FormRadio
                  title="Is this the first time you were prescribed (Sacubitril/Valsartan) Entresto?"
                  isRequired
                  options={medicationHistoryOptions}
                  value={values.medicationHistory}
                  name="medicationHistory"
                />
              </Section>
            ) : null}
            {healthSurveyType === "a" ? (
              <Section title="Customer Satisfaction">
                <CheckBoxSection
                  title="What services can we improve to help you stay longer in the Pulse Care Program?"
                  isRequired
                  options={customerSatisfactionOptions}
                  values={values.customerSatisfaction}
                  fieldName="customerSatisfaction"
                  setFieldValue={setFieldValue}
                />
              </Section>
            ) : null}
            <div className="buttons is-centered">
              <button
                className={classNames("button is-primary is-medium", {
                  "is-loading": !!loading,
                })}
                type="submit"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ContentFold>
  )
}

export default HealthSurvey
