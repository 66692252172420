import React from "react"
import Media from "react-media"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import styles from "./navigation.module.scss"
import mgNovartisLogo from "../../assets/images/mgnovartis-logo.svg"
import { useTranslation } from "react-i18next"

const LanguageToggle = () => {
  const { i18n } = useTranslation()
  const language = i18n.language

  return (
    <>
      <div
        className={classNames(styles.tabs, styles.isToggle, "tabs is-toggle")}
      >
        <ul className="navbar-item">
          <li
            className={classNames("button", {
              "is-primary": language === "en",
            })}
            onClick={() => i18n.changeLanguage("en")}
          >
            EN
          </li>
          <li
            className={classNames("button", {
              "is-primary": language === "tl",
            })}
            onClick={() => i18n.changeLanguage("tl")}
          >
            TL
          </li>
        </ul>
      </div>
    </>
  )
}

export const MobileTitleBar = isMobile => {
  // const { t } = useTranslation()
  return (
    <nav
      id="mobileTitleBar"
      className={classNames(
        styles.titleBar,
        "columns is-gapless is-mobile p-1 navbar is-fixed-top is-vcentered mb-0",
        { "is-hidden": !isMobile }
      )}
    >
      <div className="column is-8">
        <Link to="/">
          <img
            className="navbar-brand"
            src={mgNovartisLogo}
            alt="MedGrocer - Novartis"
          />
        </Link>
      </div>
      <div className="column is-4 has-text-right">
        {/* <Link to="/#entresto" id="aboutEntresto" className="is-primary">
          {t('About Entresto')}
        </Link> */}
        <LanguageToggle />
      </div>
    </nav>
  )
}

export const DefaultTitleBar = ({ pathname }) => {
  const { t } = useTranslation()

  return (
    <nav
      id="defaultTitleBar"
      className={classNames(
        "navbar has-background-light is-fixed-top",
        styles.titleBar
      )}
      role="navigation"
      aria-label="main navigation"
    >
      <Link
        id="homePageLink"
        className={classNames(styles.navbarBrand, "navbar-brand")}
        to="/"
      >
        <img src={mgNovartisLogo} alt="MedGrocer - Novartis" />
      </Link>
      <div className="navbar-menu">
        <div className="navbar-end">
          <Link
            to="/cart"
            className={classNames(styles.navbarItem, {
              [styles.isActive]: pathname === "/cart",
            })}
          >
            {t("Cart")}
          </Link>
          {/* <Link
            to="/about-entresto"
            className={classNames(styles.navbarItem, {
              [styles.isActive]: pathname === '/calculator',
            })}
            // id="aboutEntresto"
            // className="button is-primary"
          >
            {t('About Entresto')}
          </Link> */}
          <LanguageToggle />
        </div>
      </div>
    </nav>
  )
}

const TitleBar = ({ pathname }) => (
  <StaticQuery
    query={graphql`
      query {
        mgNovartis: file(relativePath: { eq: "mg-novartis.png" }) {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    //TODO - DRY
    render={data => (
      <Media query={{ maxWidth: 853 }}>
        {matches =>
          matches ? (
            <MobileTitleBar data={data} isMobile={matches} />
          ) : (
            <DefaultTitleBar data={data} pathname={pathname} />
          )
        }
      </Media>
    )}
  />
)
export default TitleBar
