import axios from 'axios'

const zendeskApiKey = process.env.GATSBY_ZENDESK_API_KEY
const UPLOAD_HEADER = { headers: { 'Content-Type': 'application/binary' } }

export const zendeskApi = (email = process.env.GATSBY_ZENDESK_EMAIL) => {
  let zendeskKey = email + '/token:' + zendeskApiKey
  let encryptedKey = Buffer.from(zendeskKey).toString('base64')
  let api = axios.create({
    baseURL: process.env.GATSBY_ZENDESK_API_URL,
    headers: {
      Authorization: 'Basic ' + encryptedKey,
      'Content-Type': 'application/json',
    },
  })
  return api
}

/**
 * ****************** UTILITY FUNCTION **********************
 * Extract all the file tokens from raw response of upload file/s.
 * @param {Object []} rawFileTokens
 */
export const generateFileUploadTokens = rawFileTokens =>
  rawFileTokens.map(response => {
    return response.data.upload.token
  })

/**
 * Convert file to Blob Object for upload
 * @param {base64} b64Data
 * @param {String} contentType
 * @param {Number} sliceSize (Optional)
 */
const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ''
  sliceSize = sliceSize || 512
  let byteCharacters = atob(b64Data)
  let byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)
    let byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  let blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const zendeskFileUpload = fileList => {
  const uploadQueue = fileList.map(file => {
    const fileBuff = file.buffer.split(',')
    const fileBlob = b64toBlob(fileBuff[1], fileBuff[0].match(/:(.*?);/)[1])
    const routeString = `/uploads.json?filename=${file.name}`
    return zendeskApi().post(routeString, fileBlob, UPLOAD_HEADER)
  })
  return axios.all(uploadQueue)
}
