import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import Media from 'react-media'
import styles from './navigation.module.scss'
import ShoppingCart from '../../assets/images/icons/shopping-cart'
import Calculator from '../../assets/images/icons/calculator'
import Home from '../../assets/images/icons/home'

const LinkButton = ({ to, isActive, label, children }) => (
  <Link
    className={classNames(
      styles.navLink,
      { 'has-background-primary has-text-white': isActive },
      'is-large column'
    )}
    to={to}
  >
    {children}
    <div className="is-size-7">{label}</div>
  </Link>
)

const linkButtonPropTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
}

const NavigationBar = ({ pathname, disableMobileNavigation }) => {
  return (
    <>
      {!disableMobileNavigation && (
        <Media
          query={{ maxWidth: 853 }}
          render={() => (
            <nav
              className="navbar mobile-navbar is-fixed-bottom is-light"
              role="navigation"
              aria-label="main-navigation"
            >
              <div className="columns is-mobile is-gapless has-text-centered has-text-grey">
                <LinkButton
                  to="/calculator"
                  label="Calculator"
                  isActive={pathname === '/calculator'}
                >
                  <Calculator isActive={pathname === '/calculator'} />
                </LinkButton>
                <LinkButton
                  to="/patient"
                  label="Home"
                  isActive={pathname === '/patient'}
                >
                  <Home isActive={pathname === '/patient'} />
                </LinkButton>
                <LinkButton
                  to="/cart"
                  label="Cart"
                  isActive={
                    pathname === '/cart' ||
                    pathname === '/review-order' ||
                    pathname === '/checkout'
                  }
                >
                  <ShoppingCart
                    isActive={
                      pathname === '/cart' ||
                      pathname === '/review-order' ||
                      pathname === '/checkout'
                    }
                  />
                </LinkButton>
              </div>
            </nav>
          )}
        />
      )}
    </>
  )
}
const navbarPropTypes = {
  pathname: PropTypes.string.isRequired,
  disableMobileNavigation: PropTypes.bool,
}

LinkButton.propTypes = linkButtonPropTypes
NavigationBar.propTypes = navbarPropTypes

export default NavigationBar
