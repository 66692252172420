import React, { Fragment, useRef, useEffect } from "react"
import { Field, ErrorMessage } from "formik"
import classNames from "classnames"
import styles from "./utils/form.module.scss"

const FormRadio = ({
  name,
  options,
  value,
  onChange,
  title,
  isInline,
  className,
  disabled,
  isRequired,
  hideOptional,
  followUpQuestions = [],
  formFields,
  formValues,
  setFieldValue,
  isFollowUpQuestion,
}) => {
  const fieldRef = useRef(null)

  const handleScrollCallback = () => {
    fieldRef.current.scrollIntoView({ scroll: "smooth", block: "center" })
  }

  useEffect(() => {
    if (isFollowUpQuestion) handleScrollCallback()
  }, [isFollowUpQuestion])

  const handleChange = (form, option) => event => {
    const { setFieldValue } = form
    if (event.target.checked) setFieldValue(name, event.target.value)
    if (onChange) onChange(event)
  }

  const RadioButton = ({ form, option, index }) => (
    <div className="mb-1" id={`option${index}`}>
      <input
        className="radio is-checkradio"
        id={`option${index + 1}RadioButton${name}`}
        type="radio"
        name={name}
        value={option}
        onChange={handleChange(form, option)}
        checked={option === value}
        disabled={disabled}
      />
      <label
        className={classNames(
          "radio-label is-size-6",
          styles["form__radioLabel"],
          {
            "mr-2": !isInline,
          }
        )}
        for={`option${index + 1}RadioButton${name}`}
      >
        {option}
      </label>
    </div>
  )

  let radioGroup = ""
  if (className) {
    radioGroup = className.radioGroup
  }

  return (
    <div className="mb-2">
      <Field name={name}>
        {({ form }) => (
          <Fragment>
            {title && (
              <label
                ref={fieldRef}
                className={classNames(`label mr-1 is-size-6`, {
                  "has-text-weight-bold": !!isRequired,
                  "form__radioLabel--displayInline": isInline,
                })}
              >
                {title}{" "}
                {!!isRequired && <span className="has-text-danger"> *</span>}
              </label>
            )}
            <div
              className={classNames(radioGroup || "", {
                "is-flex": isInline,
              })}
            >
              {options.map((option, index) => (
                <Fragment>
                  <RadioButton form={form} option={option} index={index} />
                </Fragment>
              ))}
            </div>
            <p className="help is-danger mt-0 mb-1">
              <ErrorMessage name={name} />
            </p>
          </Fragment>
        )}
      </Field>
    </div>
  )
}

export default FormRadio
