import { zendeskApi } from "../../../utils/zendeskService"

const getHealthSurveyTicketBody = values => {
  return `When you fail to take Entresto at the right time, what are the usual reasons? ${values.barriersToAdherence.join(
    ", "
  )}\nWhat services can we improve to help you stay longer in the Pulse Care Program? ${values.customerSatisfaction.join(
    ", "
  ) ||
    ""}\nIs this the first time you were prescribed (Sacubitril/Valsartan) Entresto? ${values.medicationHistory ||
    "N/A"}`
}

export const handleSendHealthSurvey = async ({
  values,
  callback,
  errorCallback,
  healthSurveyType,
}) => {
  try {
    const IS_TEST = process.env.NODE_ENV !== "production"
    let ACTIVE_PATIENT = healthSurveyType === "a"
    let tags = ["health_survey", "novartis"]

    if (IS_TEST) {
      tags.push("test")
    }

    if (!!ACTIVE_PATIENT) {
      tags.push("active_patient")
    } else {
      tags.push("inactive_patient")
    }

    let subject = `${IS_TEST ? "[TEST] " : ""}${
      !!ACTIVE_PATIENT ? "Active" : "Inactive"
    } Patient Health Survey`

    let requestBody = {
      type: "request",
      tags,
      subject,
      requester: {
        email: "pulsecare@medgrocer.com",
      },
      comment: {
        body: getHealthSurveyTicketBody(values),
      },
      custom_fields: [
        {
          id: process.env.GATSBY_ZENDESK_WEBSITE_STATE_FIELD_ID,
          value: JSON.stringify(values),
        },
      ],
    }

    await zendeskApi().post("/requests.json", { request: requestBody }, [])
    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback(error)
  }
}
