import React, { useState, useEffect, Fragment } from "react"
import { ErrorMessage, Field } from "formik"

const CheckBoxSection = ({
  title,
  options,
  values,
  inputClass,
  setFieldValue,
  fieldName,
  isRequired,
  hideOptional,
}) => {
  const [optionValues, setOptionValues] = useState(values)
  useEffect(() => {
    values = optionValues
  }, [optionValues])
  const handleSetOptionToValues = (event, option) => {
    if (event.target.checked) {
      values.push(option)
      setOptionValues(values)
      setFieldValue(fieldName, values)
    } else {
      const index = optionValues.findIndex(value => value === option)
      values.splice(index, 1)
      setOptionValues(values)
      setFieldValue(fieldName, values)
    }
  }

  return (
    <>
      <label className="label mb-2 has-text-weight-bold">
        {title} {isRequired && <span className="has-text-danger">*</span>}
      </label>

      {options.map((option, index) => (
        <div className="mb-1">
          <label className="checkbox" key={index}>
            <Field
              type="checkbox"
              value={option}
              onChange={event => handleSetOptionToValues(event, option)}
              className={inputClass}
              checked={values.includes(option)}
            />
            &nbsp; &nbsp; {option}
          </label>
        </div>
      ))}
      <p className="help is-danger mt-0 mb-1">
        <ErrorMessage name={fieldName} />
      </p>
    </>
  )
}

export default CheckBoxSection
