import React, { useState } from 'react'
import classNames from 'classnames'
// import styles from './page-content.module.scss'

const HolidayNotification = ({
  message,
  notificationClassName,
  showDelete,
}) => {
  const [isHidden, setNotificationHidden] = useState(false)

  return (
    <div
      className={classNames(
        'notification has-text-centered inquiries-banner',
        notificationClassName,
        { 'is-hidden': isHidden }
      )}
      style={{ marginBottom: '0' }}
    >
      {showDelete && (
        <button
          className="delete pt-1"
          onClick={() => setNotificationHidden(true)}
        ></button>
      )}
      <div className="notification-body">{message}</div>
    </div>
  )
}

export default HolidayNotification
