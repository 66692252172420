import React, { Fragment } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { StaticQuery, graphql } from "gatsby"
import { ApolloConsumer, Query } from "react-apollo"
import Media from "react-media"
import gql from "graphql-tag"
import moment from "moment"

import NavigationBar from "./navigation/navigation-bar"
import HolidayNotification from "./page-content/notification"
import TitleBar from "./navigation/title-bar"
import Toast from "../components/page-content/toast"
// import Loading from '../components/elements/loading'

export const GET_HOLIDAY = gql`
  query TodaysDate($holiday_date: date!, $domain: jsonb) {
    holiday(
      where: {
        holiday_date: { _eq: $holiday_date }
        domains: { _contains: $domain }
      }
    ) {
      name
      holiday_date
      short_message
    }
  }
`

const Layout = ({
  children,
  heading,
  isCentered,
  isLight,
  sectionClassName,
  disableBottomNavbar,
  disableNavbarEnd,
  location,
  showBanner,
}) => {
  const { t } = useTranslation()

  const inquiriesMessage = (
    <Fragment>
      {t("For inquiries, contact us at")}{" "}
      <b>
        <a
          href="mailto:pulsecare@medgrocer.com?subject=Pulse%20Care%20Inquiry&amp;body=Thank%20you%20for%20reaching%20out!%20Let%20us%20know%20how%20we%20can%20help%20you.%0A%0AName%3A%20%0AMessage%2FInquiry%3A%20"
          target="_blank"
          rel="noopener noreferrer"
        >
          pulsecare@medgrocer.com
        </a>
      </b>{" "}
      {t("or ")}
      <b>
        <a
          href="sms:+639178482088?body=%5BPulse%20Care%20Inquiry%5D%0A%0AName%3A%20%0AMessage%2FInquiry%3A%20"
          // target="_blank"
          rel="noopener noreferrer"
        >
          0917 848 2088
        </a>
      </b>
      .
    </Fragment>
  )

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={data => (
        <ApolloConsumer>
          {client => (
            <Query
              query={GET_HOLIDAY}
              variables={{
                holiday_date: moment().format("YYYY-MM-DD"),
                domain: process.env.GATSBY_AWS_S3_BUCKET_NAME,
              }}
            >
              {({ data, loading, error }) => {
                const holiday = data && data.holiday
                const notificationMessage =
                  !holiday || (holiday && holiday.length === 0)
                    ? null
                    : holiday[0].short_message

                return (
                  <Fragment>
                    <TitleBar
                      disableNavbarEnd={disableNavbarEnd}
                      pathname={!!location ? location.pathname : ""}
                    />

                    {showBanner && (
                      <Media query={{ maxWidth: 853 }}>
                        {/* matches means we're on mobile */}
                        {matches => {
                          return (
                            <Fragment>
                              {notificationMessage ? (
                                <Fragment>
                                  <HolidayNotification
                                    message={
                                      <Fragment>
                                        {inquiriesMessage}
                                        <br />
                                        {notificationMessage}
                                      </Fragment>
                                    }
                                    notificationClassName={classNames(
                                      "is-warning"
                                    )}
                                  />
                                </Fragment>
                              ) : (
                                <HolidayNotification
                                  message={inquiriesMessage}
                                  notificationClassName={classNames(
                                    "is-warning"
                                  )}
                                />
                              )}
                            </Fragment>
                          )
                        }}
                      </Media>
                    )}

                    <Toast />

                    <section>
                      {heading && (
                        <h1
                          className={classNames(
                            "has-text-primary has-text-weight-bold is-size-3 py-1 pt-3",
                            { "has-text-centered": isCentered }
                          )}
                        >
                          {heading}
                        </h1>
                      )}
                    </section>
                    <section
                      className={classNames(
                        { "has-background-light": isLight },
                        { [`${sectionClassName}`]: sectionClassName }
                      )}
                    >
                      {children}
                    </section>
                    {disableBottomNavbar ? (
                      ""
                    ) : (
                      <Fragment>
                        <div className="navbar-padding is-invisible" />
                        <NavigationBar
                          pathname={!!location ? location.pathname : ""}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )
              }}
            </Query>
          )}
        </ApolloConsumer>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  isCentered: PropTypes.bool,
  isLight: PropTypes.bool,
  sectionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disableBottomNavbar: PropTypes.bool,
  disableNavbarEnd: PropTypes.bool,
  location: PropTypes.object,
}

export default Layout
