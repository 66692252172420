export const barriersToAdherenceOptions = [
  "You find it difficult to buy your medicines in person from retail pharmacies",
  "You find it difficult to order your medicines online",
  "You lack caregiver support to assist you in ordering your medicines",
  "You take several medicines every day",
  "You find it difficult to visit your doctor to renew your prescription",
  "You find it difficult to pay for your medicines",
  "You find the schedule of taking your medicines inconvenient",
]

export const customerSatisfactionOptions = [
  "Delivery time",
  "Enrollment process",
  "Ordering process",
  "Customer service",
  "Website navigation",
  "Adherence reminders",
]

export const medicationHistoryOptions = [
  "Yes, I was recently diagnosed",
  "Yes, I switched from my previous medication",
  "No, I have been using this brand for a while now",
]

export const healthSurveyInitialValues = {
  barriersToAdherence: [],
  customerSatisfaction: [],
  medicationHistory: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  email: "",
}
